import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Spin } from 'antd';
import Hls from 'hls.js';
import { memo, useState, useEffect, useRef, useCallback, type MouseEvent } from 'react';
import styled from 'styled-components';

import useQueryVideoStreamV2 from '~/apollo/hooks/videoStream/useQueryVideoStreamV2';
import Text from '~/components/Text';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { kvsLogger } from '~/utils/logger';

const GUTTER = '8px';
const VIDEO_PLAYER_BACKGROUND_COLOR = '#333333';
const REFRESH_INTERVAL = 1_000; // 1 second
const CHECK_ACTIVITY_INTERVAL = 1_000; // 1 second
const SEEK_TO_LIVE_EDGE_INTERVAL = 5_000; // 5 seconds

const VideoWrapperDiv = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const PositionRelativeDiv = styled.div`
  position: relative;
  display: block;
`;

const StyledText = styled(Text)`
  height: ${theme.dimensions.videoStreamPlayerHeight}px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

const VideoPopupDiv = styled.div`
  top: ${GUTTER};
  left: ${GUTTER};
  color: ${theme.colors.darkGrey};
  width: calc(100% - ${GUTTER} - ${GUTTER});
  padding: 12px;
  position: absolute;
  border-radius: 8px;
  pointer-events: none;
  background-color: ${theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 6px;
`;

interface Props {
  agent: Agent | undefined;
  isLoadingOrRunning: boolean;
  isStartOrRequestStartLoading: boolean;
  shouldPlayVideoFromAlarm: boolean;
}

const VideoStreamPlayerKvs = memo(
  ({
    agent,
    isLoadingOrRunning,
    isStartOrRequestStartLoading,
    shouldPlayVideoFromAlarm,
  }: Props) => {
    const { videoStreamV2, isLoading: isLoadingVideoStreamV2 } = useQueryVideoStreamV2({
      agent,
    });

    const playbackUrl = videoStreamV2?.playback_url || agent?.maybeVideoStreamV2PlaybackUrl;

    const isLoading = isLoadingOrRunning || isLoadingVideoStreamV2;
    const [isHlsLoading, setIsHlsLoading] = useState<boolean>(true);

    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState<number>(0);

    const hlsInstanceRef = useRef<Hls | null>(null);
    const lastActivityRef = useRef<number>(Date.now());
    const checkActivityIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const videoRef = useRef<HTMLVideoElement>(null);

    const refreshStream = useCallback(() => {
      if (hlsInstanceRef.current) {
        hlsInstanceRef.current.destroy();
        hlsInstanceRef.current = null;
      }
      setIsHlsLoading(true);
      setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
      lastActivityRef.current = Date.now();
    }, []);

    const handleMouseOut = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(false);
    }, []);

    const handleMouseOver = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(true);
    }, []);

    // Setup periodic activity check
    useEffect(() => {
      if (!playbackUrl) {
        return () => undefined;
      }

      const checkActivity = () => {
        const timeSinceLastActivity = Date.now() - lastActivityRef.current;

        if (timeSinceLastActivity > REFRESH_INTERVAL) {
          kvsLogger.log({
            agentId: agent?.id,
            metricName: 'StreamAutoRefresh',
            data: {
              timeSinceLastActivity,
              playbackUrl,
            },
          });
          refreshStream();
        }
      };

      checkActivityIntervalRef.current = setInterval(checkActivity, CHECK_ACTIVITY_INTERVAL);

      return () => {
        if (checkActivityIntervalRef.current) {
          clearInterval(checkActivityIntervalRef.current);
        }
      };
    }, [agent?.id, playbackUrl, refreshStream]);

    useEffect(() => {
      if (!videoRef.current || !playbackUrl) {
        return () => undefined;
      }

      setIsHlsLoading(true);

      // Create named event handler functions instead of anonymous functions
      const handleStalled = () => {
        kvsLogger.error({
          agentId: agent?.id,
          metricName: 'StreamEventStalled',
          data: {
            playbackUrl,
          },
        });
      };

      const handlePlaying = () => {
        kvsLogger.log({
          agentId: agent?.id,
          metricName: 'StreamEventPlaying',
          data: {
            playbackUrl,
          },
        });
        lastActivityRef.current = Date.now();
        setIsHlsLoading(false);
      };

      const handleTimeUpdate = () => {
        lastActivityRef.current = Date.now();
      };

      // Add event listeners
      videoRef.current.addEventListener('stalled', handleStalled);
      videoRef.current.addEventListener('playing', handlePlaying);
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      videoRef.current.addEventListener('suspend', handleStalled);

      if (Hls.isSupported()) {
        const hls = new Hls({
          maxBufferLength: 10, // 10 seconds for lower latency
          maxMaxBufferLength: 30, // 30 seconds
          maxBufferSize: 60 * 1000 * 1000, // 60MB
          maxBufferHole: 0.5, // 50%
          lowLatencyMode: true, // Enable low latency mode
          backBufferLength: 10, // 10 seconds
          enableWorker: true, // Enable web worker for better performance
          fragLoadingTimeOut: 20000, // Increase timeout for fragment loading
          manifestLoadingTimeOut: 20000, // Increase timeout for manifest loading
          levelLoadingTimeOut: 20000, // Increase timeout for level loading
          startLevel: -1, // Auto start level selection
          abrEwmaDefaultEstimate: 500000, // Increase bandwidth estimate
          abrMaxWithRealBitrate: true, // Use real bitrate for ABR
          progressive: true, // Enable progressive loading
          testBandwidth: true, // Enable bandwidth testing
          liveSyncDurationCount: 1, // Buffer 1 segment for live streams
          liveMaxLatencyDurationCount: 2, // Keep max latency higher than sync duration
          liveDurationInfinity: true, // Treat as infinite live stream
          liveBackBufferLength: 0, // Don't keep unnecessary back buffer
        });

        hlsInstanceRef.current = hls;
        hls.loadSource(playbackUrl);
        hls.attachMedia(videoRef.current);

        // Add error handling and recovery
        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            const logData = {
              event,
              data,
            };
            kvsLogger.error({
              agentId: agent?.id,
              metricName: `PlayerFatalError-${data.type}`,
              data: logData,
            });
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                kvsLogger.log({
                  agentId: agent?.id,
                  metricName: `PlayerTryToRecoverFromError-${data.type}`,
                  data: logData,
                });
                hls?.startLoad(); // Try to recover
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                kvsLogger.log({
                  agentId: agent?.id,
                  metricName: `PlayerTryToRecoverFromError-${data.type}`,
                  data: logData,
                });
                hls?.recoverMediaError(); // Try to recover
                break;
              default:
                // Instead of just destroying, try to refresh
                refreshStream();
                break;
            }
          }
        });

        // Monitor for level switching to ensure we're getting the best quality
        hls.on(Hls.Events.LEVEL_SWITCHED, () => {
          lastActivityRef.current = Date.now();
        });

        hls.on(Hls.Events.MANIFEST_PARSED, async () => {
          try {
            if (videoRef.current) {
              videoRef.current.currentTime = Number.MAX_SAFE_INTEGER; // Seek to latest frame
              await videoRef.current.play();
            }
            kvsLogger.log({
              agentId: agent?.id,
              metricName: 'PlayerStarted',
              data: {
                playbackUrl,
              },
            });
          } catch (error) {
            kvsLogger.error({
              agentId: agent?.id,
              metricName: 'PlayerError',
              data: {
                playbackUrl,
                error,
              },
            });
          }
        });

        // Periodically seek to live edge
        const seekToLiveEdge = setInterval(() => {
          if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.currentTime = Number.MAX_SAFE_INTEGER;
          }
        }, SEEK_TO_LIVE_EDGE_INTERVAL);

        return () => {
          clearInterval(seekToLiveEdge);
        };
      }
      kvsLogger.error({
        agentId: agent?.id,
        metricName: 'PlayerUnsupportedBrowser',
        data: {
          playbackUrl,
        },
      });

      // Log when first frame is displayed
      videoRef.current.addEventListener('loadeddata', () => {
        kvsLogger.log({
          agentId: agent?.id,
          metricName: 'PlayerFirstFrameLoaded',
          data: {
            playbackUrl,
          },
        });
        lastActivityRef.current = Date.now();
        setIsHlsLoading(false);
      });

      // Store references to the event handlers for cleanup
      const videoRefCurrent = videoRef.current;

      return () => {
        if (hlsInstanceRef.current) {
          hlsInstanceRef.current.destroy();
          hlsInstanceRef.current = null;
        }
        if (videoRefCurrent) {
          // Remove event listeners using the same named functions
          videoRefCurrent.removeEventListener('stalled', handleStalled);
          videoRefCurrent.removeEventListener('playing', handlePlaying);
          videoRefCurrent.removeEventListener('timeupdate', handleTimeUpdate);
          videoRefCurrent.removeEventListener('suspend', handleStalled);
        }
      };
    }, [playbackUrl, agent?.id, refreshKey, refreshStream]);

    if (!agent) {
      return null;
    }

    const isVideoSteamRunning = !!playbackUrl;

    const videoPlaceholderText = isStartOrRequestStartLoading
      ? i18n.t('common.loading')
      : i18n.t('carrierDetailsPopup.videoStreaming.offline');

    const mainText = shouldPlayVideoFromAlarm
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : videoPlaceholderText;

    const popupText = isStartOrRequestStartLoading
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : i18n.t('carrierDetailsPopup.videoStreaming.videoWillStartStreaming');

    return (
      <VideoWrapperDiv>
        {isVideoSteamRunning && playbackUrl ? (
          <PositionRelativeDiv>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              key={refreshKey}
              ref={videoRef}
              width="100%"
              height={theme.dimensions.videoStreamPlayerHeight}
              playsInline
              controls={false}
              disablePictureInPicture
              autoPlay
              data-id="video-stream-player"
              style={{
                backgroundColor: 'black',
                borderRadius: '6px',
                overflow: 'hidden',
              }}
            />
            {isHlsLoading && (
              <LoadingOverlay>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 32, color: '#fff' }} spin />}
                />
              </LoadingOverlay>
            )}
          </PositionRelativeDiv>
        ) : (
          <PositionRelativeDiv data-id="video-stream-placeholder">
            {isPopupVisible && <VideoPopupDiv>{popupText}</VideoPopupDiv>}
            <StyledText
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              style={{
                backgroundColor: isLoading
                  ? VIDEO_PLAYER_BACKGROUND_COLOR
                  : theme.colors.greyBackground,
                color: isLoading ? theme.colors.white : theme.colors.darkBlue,
              }}
            >
              {mainText}
            </StyledText>
          </PositionRelativeDiv>
        )}
      </VideoWrapperDiv>
    );
  },
);

VideoStreamPlayerKvs.displayName = 'VideoStreamPlayerKvs';

export default VideoStreamPlayerKvs;
